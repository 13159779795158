<template>
      <!--====== APPIE FOOTER PART START ======-->
    
    <section class="appie-footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="/"><img src="@/landing/assets/images/logo.png" alt="My Model Railroad"></a>
                        </div>
                        <div class="social mt-30">
                            <ul>
                                <li><a href="https://www.facebook.com/mymodelrailroadapp" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/mymodelrailroad_app" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.pinterest.com/merrimackvalleymodels"><i class="fab fa-pinterest-p"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/merrimackvalleymodels"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Company</h4>
                        <ul>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Support</h4>
                        <ul>
                            <!-- li><a href="#">Faqs</a></li -->
                            <li><router-link to="/privacy">Privacy</router-link></li>
                            <li><router-link to="/terms">Terms</router-link></li>
                        </ul>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Get In Touch</h4>
                        <ul>
                            <li><a href="mailto:support@mymodelrailroad.app"><i class="fal fa-envelope"></i> support@mymodelrailroad.app</a></li>
                            <li><a href="tel:18664872469"><i class="fal fa-phone"></i> +(866) 4TRAINZ</a></li>
                            <li><a href="#"><i class="fal fa-map-marker-alt"></i> 120 Centre Street, Danvers, MA 01923</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
                        <div class="apps-download-btn">
                        <ul>
                            <li><a href="https://apps.apple.com/us/app/my-model-railroad/id1604631366" target="_blank"><i class="fab fa-apple"></i> Download for iOS</a></li>
                            <li><a class="item-2" href="#"><i class="fab fa-google-play"></i> Download for Android</a></li>
                        </ul>
                        </div>
                        <div class="copyright-text">
                            <p>Copyright © 2022 <a href="https://www.merrimackvalleymodels.com" target="_blank">Merrimack Valley Models, LLC</a>. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
    
}
</script>

<style>

</style>