<template>
<div>
    <div v-if="showloading">
        <loader/>
    </div>
    <div v-else>
    <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======--> 

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one  :sidebar="sidebar" @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== PART START ======-->
    
    <landing-header @toggleSidebar="toggleSidebar" :menuItems="navs" />
    
    <!--====== PART ENDS ======-->

    <!--====== APPIE HERO PART START ======-->
    
    <landing-hero welcome_message="Welcome To My Model Railroad" slogan="Manage your entire model railroad collection." description="You'll always know what you already own at the tip of your fingers." :thumb_one="thumb_one" :thumb_two="thumb_two" first_button_content="Download for iOS" first_button_icon="fab fa-apple" second_button_content="Download for Android" second_button_icon="fab fa-google-play" />
    
    <!--====== APPIE HERO PART ENDS ======-->

    <!--====== APPIE SERVICES PART START ======-->
    <services-home-one :grid_items="itemsGrid" section_heading="Designed with" more_heading="The applicant in mind." description="The full monty spiffing good time no biggie cack grub fantastic."/>
    <!--====== APPIE SERVICES PART ENDS ======-->

    <!--====== APPIE FEATURES PART START ======-->
    <features-home-one/>
    
    <!--====== APPIE FEATURES PART ENDS ======-->

    <!--====== APPIE TRAFFIC PART START ======-->
    
    <traffic-home-one :traffic_thumbnail="traffic_thumb" small_title="Traffic" big_title="Stage reporting with  50k queries." description="He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty." :features="features"/>
    
    <!--====== APPIE TRAFFIC PART ENDS ======-->

    <!--====== APPIE TESTIMONIAL PART START ======-->
    
    <testimonial-home-one :sliderContents="sliderContents"/>
    
    <!--====== APPIE TESTIMONIAL PART ENDS ======-->

    <!--====== APPIE TEAM PART START ======-->
    
   <team-home-one title="Meet our Team Members" description="Different layouts and styles for team sections." :members="members"/>
    
    <!--====== APPIE TEAM PART ENDS ======-->

    <!--====== APPIE PRICING PART START ======-->
    
    <priceing-home-one title="Choose your plan" description="Different layouts and styles for team sections."/>
    
    <!--====== APPIE PRICING PART ENDS ======-->

    <!--====== APPIE FAQ PART START ======-->
    
    <faq-home-one title="Frequently asked questions" description="Different layouts and styles for team sections."/>
    
    <!--====== APPIE FAQ PART ENDS ======-->

    <!--====== APPIE BLOG PART START ======-->
    
    <blog-home-one title="Latest blog posts" description="Different layouts and styles for team sections." :blogs="blogs"/>
    
    <!--====== APPIE BLOG PART ENDS ======-->

    <!--====== APPIE PROJECT PART START ======-->
    
    <project-home-one title="Start your project with appie." description="We will email you only about this product."/>
    
    <!--====== APPIE PROJECT PART ENDS ======-->

    <!--====== APPIE FOOTER PART START ======-->
    
    <LandingFooter />
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    </div>
</div>
</template>
<script>
//import blogOne from '@/assets/images/blog-1.jpg'
//import blogTwo from '@/assets/images/blog-2.jpg'
//import blogThree from '@/assets/images/blog-3.jpg'
//import memberOne from "@/assets/images/team-1.jpg"
//import memberTwo from "@/assets/images/team-2.jpg"
//import memberThree from "@/assets/images/team-3.jpg"
//import memberFour from "@/assets/images/team-4.jpg"
import testmonialAvater from "@/landing/assets/images/testimonial-user.png"
//import traffic_thumb from '@/assets/images/traffic-thumb.png'
import icon_one from "@/landing/assets/images/icon/1.png" 
import icon_two from "@/landing/assets/images/icon/2.png" 
import icon_three from "@/landing/assets/images/icon/3.png" 
import icon_four from "@/landing/assets/images/icon/4.png" 
import LandingHeader from '../components/LandingHeader.vue'
import LandingHero from '../components/LandingHero.vue'
import LandingFooter from '../components/LandingFooter.vue'
import Loader from '../components/Loader.vue'
export default {
    name:'LandingHome',
    components: {LandingHeader, LandingHero, LandingFooter, Loader, },
    data(){
        return{
            //traffic_thumb:traffic_thumb,
            testmonialAvater:testmonialAvater,
            sidebar:false,
            showloading:true,
            navs:[
            {
                name:'home',
                path:'/'
            },
            {
                name:'contact',
                path:'/contact'
            }
            ],
             itemsGrid:[
                {
                    count:1,
                    icon:icon_one,
                    title:'Searching',
                    content:'Mucker plastered bugger all mate morish are.',
                    customClass:''

                },
                {
                    count:2,
                    icon:icon_two,
                    title:'Designing',
                    content:'Mucker plastered bugger all mate morish are.',
                    customClass:'item-2'

                },
                {
                    count:3,
                    icon:icon_three,
                    title:'Building',
                    content:'Mucker plastered bugger all mate morish are.',
                    customClass:'item-3',

                },
                {
                    count:4,
                    icon:icon_four,
                    title:'Suporting',
                    content:'Mucker plastered bugger all mate morish are.',
                    customClass:'item-4'

                },
            ],
            features:[
                {
                    title: 'Carefully designed',
                    bg:'',// create class for style
                    description:'Mucker plastered bugger all mate morish are.'

                },
                {
                    title: 'Choose a App',
                    bg:'item-2',// create class for style
                    description:'Mucker plastered bugger all mate morish are.'

                },
                {
                    title: 'Seamless Sync',
                    bg:'item-3',// create class for style
                    description:'Mucker plastered bugger all mate morish are.'

                },
                {
                    title: 'User Interactive',
                    bg:'item-4',// create class for style
                    description:'Mucker plastered bugger all mate morish are.'

                },
            ],
            sliderContents:[
                {
                    title:'Jason Response',
                    date:'April 14, 2022',
                    description:'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
                    ratings:5,
                    avatar:testmonialAvater,
                },
                {
                    title:'Jason Response',
                    date:'April 14, 2022',
                    description:'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
                    ratings:5,
                     avatar:testmonialAvater,
                },
                {
                    title:'Jason Response',
                    date:'April 14, 2022',
                    description:'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
                    ratings:2,
                     avatar:testmonialAvater,
                },
                {
                    title:'Jason Response',
                    date:'April 14, 2022',
                    description:'Why I say old chap that is spiffing lurgy Oxford cracking goal bleeder, bog-standard Harry brilliant mush a blinding shot starkers.',
                    ratings:3,
                     avatar:testmonialAvater,
                }
            ]
        }
    },
   created() {
       this.preLoading();
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        preLoading(){
            setTimeout(()=>{
                this.showloading= false    
            },2000)
        },
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>

<style>

</style>