<template>
    <Contact/>
</template>

<script>
import Contact from '../components/Contact/index'
export default {
	components: {Contact  },

}
</script>

<style>

</style>