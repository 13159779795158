import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingHome from '../landing/views/Home.vue'
//import HomeTwo from '../views/HomeTwo.vue'
//import HomeThree from '../views/HomeThree.vue'
//import HomeFour from '../views/HomeFour.vue'
//import HomeFive from '../views/HomeFive.vue'
//import HomeSix from '../views/HomeSix.vue'
//import HomeSeven from '../views/HomeSeven.vue'
//import HomeEight from '../views/HomeEight.vue'
//import News from '../views/News.vue'
//import SingleNews from '../views/SingleNews.vue'
import Privacy from '../landing/views/Privacy.vue'
import Terms from '../landing/views/Terms.vue'
import Contact from '../landing/views/Contact.vue'
//import ServiceDetails from '../views/ServiceDetails'
//import Error from '../landing/views/Error.vue'
//import AboutUs from '../views/About.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingHome',
    component: LandingHome,
    meta: {
      title: 'My Model Railroad',
      metaTags: [
        {
          name: 'description',
          content: 'An easy app to track your model railroad collection and so much more.'
        },
        {
          name: 'author',
          content: 'Merrimack Valley Models, LLC'
        },
        { 
          name: 'apple-itunes-app', 
          content: 'app-id=1604631366'
        },
        {
          property: 'og:title',
          content: 'My Model Railroad'
        },
        {
          property: 'og:description',
          content: 'An easy app to track your model railroad collection.'
        },
        {
          property: 'og:url',
          content: 'https://www.mymodelrailroad.app'
        },
        {
          property: 'og:image',
          content: '@/landing/assets/images/logo.png'
        },
        {
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  },/*,
  {
    path: '/home-two',
    name: 'HomeTwo',
    component: HomeTwo
  },
  {
    path: '/home-three',
    name: 'HomeThree',
    component: HomeThree
  },
  {
    path: '/home-four',
    name: 'HomeFour',
    component: HomeFour
  },
  {
    path: '/home-five',
    name: 'HomeFive',
    component: HomeFive
  },
  {
    path: '/home-six',
    name: 'HomeSix',
    component: HomeSix
  },
  {
    path: '/home-seven',
    name: 'HomeSeven',
    component: HomeSeven
  },
  {
    path: '/home-eight',
    name: 'HomeEight',
    component: HomeEight
  },
  {
    path: '/service-details',
    name: 'ServiceDetails',
    component: ServiceDetails
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/single-news',
    name: 'SingleNews',
    component: SingleNews
  },*/
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }/*
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ //'../views/About.vue'//)
  // }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...

export default router
