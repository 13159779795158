<template>
  <div>
       <!--====== PRELOADER PART START ======-->

    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== OFFCANVAS MENU PART START ======-->

    <sidebar-home-one :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <!--====== OFFCANVAS MENU PART ENDS ======-->
   
    <!--====== PART START ======-->
    
    <header-contact @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    
    <!--====== PART ENDS ======-->

    <!--====== APPIE PAGE TITLE PART START ======-->
    
    <!-- reference by news -->
    <title-area/>
    
    <!--====== APPIE PAGE TITLE PART ENDS ======-->

        <!-- Contact Start -->
        <contact-inputs/>
        <!-- Contact End -->


    <!--====== APPIE FOOTER PART START ======-->
    
    <LandingFooter />
    
    <!--====== APPIE FOOTER PART ENDS ======-->


    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->

  </div>
</template>

<script>
//import LandingHeader from '../LandingHeader.vue'
import LandingFooter from '../LandingFooter.vue'

//import FooterHomeOne from '../HomeOne/FooterHomeOne.vue'
//import SidebarHomeOne from '../HomeOne/SidebarHomeOne.vue'
//import TitleArea from '../News/TitleArea.vue'
import ContactInputs from './ContactInputs.vue'
import HeaderContact from './HeaderContact.vue'
export default {
	components: { HeaderContact, ContactInputs, LandingFooter },
        data(){
        return{
            sidebar:false,
              navs:[
            {
                name:'home',
                path:'/'
            },
            {
                name:'contact',
                    path:'/contact'
            }
            ]
        }
    },
     mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
         toggleSidebar(){
           this.sidebar = !this.sidebar
        },  
    }

}
</script>

<style>

</style>