<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'My Model Railroad',
    titleTemplate: '%s | My Model Railroad',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'My Model Railroad is the easiest way to track and manage your own model railroad collection.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'apple-itunes-app', content: 'app-id=1604631366'}
    ]
  },
}
</script>

<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>

<style>
</style>

